.table {
  > li {
    width: 100%;
    @include rtl-sass-value(float, left, right);
    display: none;

    &.active {
      display: block;
    }
  }
}
.scopeItem {
  color: $red-orange;
  cursor: pointer;
}
.scopeItem:hover {
  color: $white;
}
@media (max-width: 768px) {
  .tab li {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .tab {
    li {
      width: 100%;
      white-space: inherit;

      &.el-button {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
.header_btn_container {
  display: flex;
  li {
    border-radius: 0;
    background: none;
    border: none;
    height: 60px;
    border-left: 1px $black solid;
    line-height: 60px;
    margin: 0 auto;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 300;
    color: $yellow1;
    border-bottom: 2px $yellow1 solid;
    box-sizing: border-box;
    cursor: pointer;
  }
  .is-plain {
    color: $white;
    border-bottom: none;
  }
}
.item_header {
  @include screen-mobile() {
    height: 200px !important;
    // line-height: 200px !important;
    flex-direction: column;
    display: block !important;
    padding: 0 !important;
  }
  .header_btn_container {
    @include screen-mobile() {
      display: block;
    }
  }
}
