@import '@/assets/css/components/table.scss';
@import '@/assets/css/components/tab.scss';
@import '@/assets/css/components/defaultDatePickerLayout.scss';

.rebate_payment_history {
  .query {
    margin-top: 67px;

    .query_content_main {
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 65% 12%;
      grid-template-rows: auto;
      grid-template-areas: 'calendar right_box';
      column-gap: 3%;

      .calendar {
        grid-area: calendar;
      }

      .right_box {
        grid-area: right_box;
      }
    }
  }

  .box {
    margin-top: 38px;
  }

  .select_table_box li {
    display: none;

    &.active {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .rebate_payment_history .query {
    margin-bottom: 20px;

    .query_content_main {
      grid-template-columns: 100%;
      grid-template-areas:
        'calendar'
        'right_box';

      .right_box {
        margin-top: 20px;
        justify-self: center;
      }
    }
  }
}

@include screen-mobile() {
  .item_header {
    height: unset !important;
    line-height: unset !important;
    display: block !important;
  }

  .tranhis {
    display: block !important;
    align-items: center;

    p {
      height: 60px;
      line-height: 60px;
    }
  }

  .header_btn_container {
    display: flex !important;

    li {
      flex: 1;
      overflow: hidden;
      padding: 0;
      text-align: center;
      flex-shrink: 0;
    }
  }
}